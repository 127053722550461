html, body, #root {
    height: 100vh;
    font-family: 'Source Serif Pro', serif;
}

html {
    background-color: #b3b3bf;
}

body {
    margin: 1rem 3rem;
}
  

.App {
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    body {
      font-size: 14px;
    }
}

@media (max-width:500px) {
    body {
      font-size: 12px;
    }
}

@media (max-width:300px) {
    body {
      font-size: 10px;
    }
}