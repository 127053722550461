.Content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 3rem auto;

    .Content-title {
        align-self: center;
        padding-bottom: 1rem;
    }

    .Content-service {
        display: flex;
        flex-direction: column;
    }

    .Content-service-description {
        display: flex;
        flex-direction: column;
        gap: .7rem;
        font-size: 1.1rem;
    }

    .Content-service-title {
        align-self: center;
    }
    
}